import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import SolutionsData from "../../content/solutions/index.json"
import {Container} from "react-bootstrap";
import SimpleBlock from "../../components/misc/simple-block";
import HeroPages from "../../components/heroes/hero-pages";

const SolutionsIndexPage = ({ intl, location }) => (
  <Layout>
    <Seo lang={intl.locale} title={SolutionsData[intl.locale].title} description={SolutionsData[intl.locale].text} pathname={location.pathname} />
      <HeroPages
          theme={`primary`}
          content={SolutionsData[intl.locale]}
      />
      <Container>
          {SolutionsData[intl.locale].industries.map((item, i) => {

              return (
                  <SimpleBlock key={i} content={item} />
              )

          })}
      </Container>
  </Layout>
)

export default injectIntl(SolutionsIndexPage)
